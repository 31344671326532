import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import germanFlag from '../../../assets/images/flags/german-flag.png'
import americanFlag from '../../../assets/images/flags/american-flag.png'

function selectFlag(locale) {
  switch (locale) {
    case 'de':
      return americanFlag
    case 'en':
      return germanFlag
    default:
      return germanFlag
  }
}

const LangSelector = ({ onChangeLang, locale }) => {
  const onClick = useCallback(() => onChangeLang(locale), [
    onChangeLang,
    locale,
  ])
  return (
    <button
      className="lang-selector"
      onClick={onClick}
      aria-label="button language selection"
    >
      <img src={selectFlag(locale)} alt="" width={26.67} height={16} />
    </button>
  )
}

LangSelector.propTypes = {
  onChangeLang: PropTypes.func,
  locale: PropTypes.oneOf(['en', 'de']),
}

export default LangSelector
