import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import useLocalizePath from '../../../hooks/useLocalizePath'

const Dropdown = ({ items, onSelect }) => {
  const t = useTranslations()
  const localizePath = useLocalizePath()
  // const location = useLocation()
  return (
    <div className="dropdown">
      <div className="dropdown-list">
        {items.map(item =>
          item.isExternal ? (
            <a
              href={item.href}
              className="drop-item"
              key={item.text}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(item.text)}
            </a>
          ) : (
            <AnchorLink
              key={item.text}
              /* to={localePath(location, item.to)} */
              to={localizePath(item.to)}
              className="drop-item"
              title={item.literal ? item.text : t(item.text)}
              onAnchorLinkClick={onSelect}
            />
          ),
        )}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  items: PropTypes.array,
}

export default Dropdown
