import React from 'react'
import PropTypes from 'prop-types'
import Link from '../links/LinkLocalized'
import Dropdown from '../dropdowns/Dropdown'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const HeaderNavItem = ({
  to,
  name,
  isLive,
  isDisabled,
  isButton,
  dropItems,
  onSelect,
}) => {
  const t = useTranslations()
  const navItemClasses = cx('nav-item-link ', {
    'is-live': isLive,
    'is-button': isButton,
    'is-disabled': isDisabled,
  })

  return (
    <div className={cx('nav-item')}>
      <div className={navItemClasses} onClick={onSelect}>
        <Link to={to} className="nav-item-text" activeClassName="active">
          {t(name)}
        </Link>
        {dropItems ? <Dropdown items={dropItems} onSelect={onSelect} /> : null}
      </div>
    </div>
  )
}

HeaderNavItem.propTypes = {
  to: PropTypes.string,
  name: PropTypes.string,
  isLive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isButton: PropTypes.bool,
  hasDropdown: PropTypes.bool,
  dropItems: PropTypes.array,
  onSelect: PropTypes.func,
}

HeaderNavItem.defaulProps = {
  hasDropdown: false,
}

export default HeaderNavItem
