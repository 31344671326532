import React, { useState, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import logoMobile from '../../../assets/images/aucta-logos/aucta-type.svg'
import Link from '../links/LinkLocalized'

import LangSelector from './LangSelector'
import HeaderNavItem from './HeaderNavItem'
import logo from '../../../assets/images/aucta-logos/aucta-logo.svg'
import logoImg from '../../../assets/images/aucta-logos/aucta-icon.svg'
import cx from 'classnames'

const Header = ({ items, locale, onChangeLang }) => {
  const [isMenuOpen, setisMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => setisMenuOpen(!isMenuOpen), [isMenuOpen])

  useLayoutEffect(() => {
    // Remove body scroll when menu is open
    // Get original body overflow
    // const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    if (isMenuOpen) {
      document.body.classList.add('disable-scroll-mobile')
    }
    // Re-enable scrolling when component unmounts
    return () => document.body.classList.remove('disable-scroll-mobile')
  }, [isMenuOpen]) // Empty array ensures effect is only run on mount and unmount

  const classes = cx('main-header', {
    'open-menu': isMenuOpen,
  })
  return (
    <header className={classes}>
      <div className="wrapper">
        <Link to="/" className="logo">
          <img
            className="logo-img show-mobile"
            alt="Aucta"
            src={logoMobile}
            width="60"
            height="16"
          />
          <img
            className="logo-img show-desktop"
            alt="Aucta"
            src={logo}
            width="90"
            height="20"
          />
        </Link>
        <Link to="/" className="middle-logo show-mobile">
          <img
            className="logo-img"
            alt="Aucta"
            src={logoImg}
            width="25"
            height="22"
          />
        </Link>
        <button
          className="button-menu show-mobile"
          onClick={toggleMenu}
          aria-label="menu"
        >
          <span className="hamburger"></span>
        </button>
        <nav className="main-nav">
          {items.map(item => (
            <HeaderNavItem
              key={item.name}
              to={item.to}
              name={item.name}
              isLive={item.isLive}
              isDisabled={item.isDisabled}
              isButton={item.isButton}
              hasDropdown={item.hasDropdown}
              dropItems={item.dropItems}
              onSelect={toggleMenu}
            />
          ))}
        </nav>

        <LangSelector locale={locale} onChangeLang={onChangeLang} />
      </div>
    </header>
  )
}

Header.propTypes = {
  items: PropTypes.array,
  onChangeLang: PropTypes.func,
  flag: PropTypes.oneOf(['english', 'german']),
}

export default Header
