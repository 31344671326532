const headerItems = [
  { name: 'how-it-works:title', to: '/how-it-works', isActive: true },
  {
    name: 'who-its-for:title',
    to: '/who-its-for',
    hasDropdown: true,
    dropItems: [
      { to: '/training', text: 'noun:training' },
      { to: '/maintenance', text: 'noun:maintenance-servicing' },
      { to: '/sales', text: 'sales:name' },
      { to: '/customer-enablement', text: 'noun:customer-enablement' },
    ],
  },
  {
    name: 'noun:case-studies',
    to: '/case-studies',
    hasDropdown: true,
    dropItems: [
      { to: '/ceramtec', text: 'Ceramtec', literal: true },
      { to: '/alphr', text: 'Alphr Technologies', literal: true },
      { to: '/eos', text: 'EOS', literal: true },
    ],
  },
  {
    name: 'noun:pricing',
    to: '/license',
  },
  { name: 'noun:why-us', to: '/why-us' },
  { name: 'noun:contact', to: '/contact' },
  {
    name: 'noun:resources',
    to: '/blog',
    dropItems: [
      { to: '/blog', text: 'noun:blog' },
      { to: '/blog/news', text: 'noun:press-coverage' },
    ],
  },
  { name: 'action:try-online-demo', to: '/demo', isButton: true },
]

const headerSimpleItems = [
  { name: 'action:try-online-demo', to: '/demo', isButton: true },
]

export { headerItems, headerSimpleItems }
